<div class="d-flex justify-content-between top-nav-container">

  <div class="left-container">
    <div class="logo-container">
        <a routerLink="/" style="margin: auto">
          <img class="full-logo" src="/assets/images/logos/chatperk-logo.svg" />
          <img class="small-logo" src="/assets/images/logos/chatperk-logo.png" />
        </a>
    </div>

    <app-space-dropdown></app-space-dropdown>
  </div>

    <div class="actions-container">

        <!-- help -->
        <div class="help-icon-container">
            <span class="help-icon">
                <sxw-svg-icon name="lifebuoy" width="20" height="20"></sxw-svg-icon>
            </span>
            <span class="help-text">Help</span>
        </div>

        <!-- user-menu-icon -->
        <div class="user-menu-icon" (click)="toggleMenu()">
          <img class="default-image" src="assets/images/avatars/user-initials-photo.svg" />
        </div>

        <div class="user-menu" *ngIf="showMenu">
            <div class="user-menu-container">
                <div class="user-info">
                    <div class="user-menu-icon">
                      <img class="default-image" src="assets/images/avatars/user-initials-photo.svg" />
                    </div>
                    <div>
                        <p> {{ authenticationService.getUser()!.fullName }} </p>
                        <p style="color:#515151;font-size:12px;">{{authenticationService.getUser()!.email}} </p>
                    </div>
                </div>

                <div class="user-actions">
                  <div class="user-action">
                    <a href="/account">
                      <sxw-svg-icon name="user" width="20" height="20" class="icon"></sxw-svg-icon>
                      <span>Account settings</span>
                    </a>
                  </div>

                  <div class="user-action">
                    <a (click)="logout()">
                      <sxw-svg-icon name="exit" width="20" height="20" class="icon"></sxw-svg-icon>
                      <span> Logout </span>
                    </a>
                  </div>
                </div>

                <div class="menu-footer">
                  <div class="link" routerLink="/terms">Terms & conditions</div>
                  <div class="dot"></div>
                  <div class="link" routerLink="/privacy">Privacy Policy</div>
                </div>
            </div>

        </div>

    </div>

</div>
